<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs"></div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :per-page="0"
                :current-page="currentPage"
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-success p-2"
                    v-if="row.item.status == 1"
                    >Accepted</span
                  >
                  <b-button
                    class="btn btn-sm btn-secondary"
                    variant="secondary"
                    v-if="row.item.status == 0"
                    @click="verificationModal(row.item)"
                    >Pending
                  </b-button>
                  <b-button
                    class="btn btn-sm btn-danger"
                    variant="danger"
                    v-if="row.item.status == 2"
                    >Rejected
                  </b-button>
                </template>
                <template v-slot:cell(articles_link)="row">
                  <b-button
                    @click="addLink(row.item.user_reference_id)"
                    class="btn btn-sm btn-info"
                    variant="info"
                    >add articles
                  </b-button>
                </template>
                <template v-slot:cell(reason)="row">
                  <template>
                    <b-button
                      class="btn btn-sm btn-primary"
                      variant="primary"
                      @click="ReasonsModal(row.item.id)"
                      >view
                    </b-button>
                  </template>
                </template>
                <template v-slot:cell(user_reference_id)="row">
                  <span
                    style="cursor: pointer; color: #7182df"
                    @click="BankDetailsModal(row.item.user_reference_id)"
                  >
                    {{ row.item.member_name }}
                  </span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    >
                    </b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <b-modal
            id="bvModal"
            v-model="showVerificationModal"
            title="MCI Verification"
            hide-footer
            centered
            @hidden="clearVerificationModel"
          >
            <template #modal-title>Verification </template>
            <div class="col-12 d-flex">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col class="d-flex justify-content-center">
                    <b-form-radio
                      v-model="form.status"
                      name="verificationStatus"
                      :value="1"
                      @change="handleVerificationChange"
                      >Accept
                    </b-form-radio></b-col
                  >
                </b-row>
                <b-row>
                  <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                    class="col-12"
                  >
                    <label style=""
                      >Reason <span style="color: red">*</span></label
                    >
                    <b-form-textarea
                      id="input-1"
                      placeholder="Enter Note"
                      class="col-12"
                      rows="4"
                      v-model="form.reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                    class="col-12"
                  >
                    <label class="">Image</label>
                    <b-form-group
                      id="input-group-icon"
                      label-for="input-icon"
                      class=""
                    >
                      <b-form-file
                        id="input-icon"
                        accept="image/*"
                        @change="readFile($event, 'image')"
                        ref="image"
                        placeholder="Choose a file [Upload Max File Size : 2MB]"
                      >
                      </b-form-file>
                    </b-form-group>
                  </b-form-group>
                </b-row>
                <b-row class="d-flex justify-content-end">
                  <section class="mt-2">
                    <b-button
                      class="btn btn-success"
                      variant="success"
                      :disabled="form.verification_status === null"
                      @click="SubmitVerificationStatus"
                    >
                      Submit
                    </b-button>
                  </section>
                </b-row>
              </b-container>
            </div>
          </b-modal>
          <b-modal
            id="bvModal"
            size="lg"
            v-model="showReasonModal"
            title="Reject Reasons"
            hide-footer
            centered
            @hidden="clearReasonModel"
          >
            <template #modal-title
              ><i
                class="mdi mdi-information-outline"
                style="color: #7182df; font-size: 20px"
              ></i>
              Reasons
            </template>
            <div
              style="max-height: 70vh !important; overflow-y: auto !important"
            >
              <div v-if="Reasons !== null && Reasons.length !== 0">
                <div v-for="(item, index) in Reasons" :key="index">
                  <div class="p-2">
                    <section class="d-flex align-items-center gap-2">
                      <viewer :images="[item?.attachment]">
                        <img
                          v-if="item?.attachment"
                          :src="item?.attachment"
                          width="115px"
                          height="115px"
                          style="object-fit: cover; margin-right: 10px"
                        />
                      </viewer>
                      {{ item.reason }}
                    </section>
                    <section class="d-flex justify-content-end">
                      {{ getSessionStart(item.created_at) }}
                    </section>
                  </div>
                </div>
              </div>
              <div class="no_content py-4" v-else>
                <div style="display: grid; justify-items: center">
                  <img src="/noData.svg" alt="" />
                  <span class="no_content_text">No Data Available</span>
                </div>
              </div>
            </div>
          </b-modal>

          <b-modal
            id="bvModal"
            v-model="showBankDetailsModal"
            title="Back Details"
            hide-footer
            centered
            @hidden="clearBankDetailsModal"
          >
            <template #modal-title
              ><i class="mdi mdi-bank" style="color: #7182df"></i> Bank Details
            </template>
            <div class="row col-12">
              <section
                v-for="(value, key) in bankDetails"
                :key="key"
                class="col-12 m-2"
                style="font-size: 17px"
              >
                <b>{{ key }} :</b> {{ value ?? "---" }}
              </section>
            </div>
          </b-modal>
          <b-modal
            id="bvModal"
            ref="modal"
            title="Link Details"
            @ok="handleLinks"
            @cancel="closeModal"
            v-model="is_link_modal"
            no-close-on-backdrop
            centered
            hide-header-close
            :ok-title="'Add'"
            cancel-title="Close"
            size="lg"
          >
            <form ref="form" @submit.stop.prevent="handleSubmitLinks">
              <b-form-group>
                <label>URL Link </label>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 0.3fr;
                    grid-gap: 10px;
                  "
                >
                  <b-form-input
                    type="text"
                    id="url_link"
                    placeholder="Enter URL Link"
                    @change="changePostUrl"
                    v-model="form2.url"
                  />
                  <b-button
                    class="btn-info btn-sm"
                    style="font-size: 15px"
                    @click="fetchMetadata"
                    :disabled="!form2.url"
                    id="fetch-url"
                    >Fetch URL</b-button
                  >
                </div>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import walletWithdrawMixin from "../../../mixins/ModuleJs/wallet-withdraw-request";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  mixins: [MixinRequest, walletWithdrawMixin],
  data() {
    return {
      title: "Wallet Withdraw",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    getSessionStart: function (session_time) {
      let dateTime1 = moment(session_time).format("MMM D, YYYY ");
      return dateTime1;
    },
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
