import axios from "axios";
import { withdrawRequest, members } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      is_update: false,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "user_reference_id",
          label: "Member",
        },
        {
          key: "mobile_number",
        },
        {
          key: "user_reference_type",
          label: "Member Type",
        },
        {
          key: "amount",
        },
        {
          key: "tax",
          label: "TDS",
        },
        {
          key: "total_amount",
        },
        {
          key: "articles_link",
          label: "Add Articles",
        },
        {
          key: "status",
        },
        {
          key: "reason",
        },
      ],
      form: {
        id: null,
        status: null,
        reason: "",
      },
      showVerificationModal: false,
      showReasonModal: false,
      showBankDetailsModal: false,
      Reasons: [],
      bankDetails: null,
      is_link_modal: false,
      form2: {
        member_id: "",
        meta_title: "",
        meta_desc: "",
        meta_image: "",
        url: "",
      },
    };
  },

  methods: {
    addLink(memeber_id) {
      this.is_add_link = true;
      this.is_link_modal = true;
      this.form2.id = memeber_id;
      this.form2.url = "";
      this.form2.meta_title = "";
      this.form2.meta_desc = "";
      this.form2.meta_image = "";
    },

    closeModal() {
      this.is_update = false;
      this.submitted = false;
    },

    handleLinks(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitLinks();
    },

    changePostUrl() {
      this.form6.meta_title = "";
      this.form6.meta_desc = "";
      this.form6.meta_image = "";
      this.removeItem = null;
    },

    async fetchMetadata() {
      this.$store.commit("loader/updateStatus", true);
      document.getElementById("fetch-url").style.border = "";
      const formattedURL = this.form2.url;
      try {
        const response = await this.getRequest(
          `${this.$hostLink}/api/metaTags?url=${formattedURL}`
        );
        if ("title" in response.metadata) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Post URL fetched successfully",
          });
          const title = response.metadata.title;
          const responseData = response.metadata;
          this.form2.meta_title = responseData.title;
          if (responseData.description) {
            this.form2.meta_desc = responseData.description;
          }
          if (responseData.image) {
            this.form2.meta_image = responseData.image;
          }
          console.log(`Title: ${title}`);
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Invalid Post URL",
          });
          console.error("Error: Title key is missing in the metadata object.");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid Post URL",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    checkFormLinkValidity() {
      if (this.form2.url == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "URL Field Required",
        });
        return false;
      }
      if (this.form2.meta_title == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please fetch URL first",
        });
        return false;
      }
      return true;
    },

    async updateLink() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.updateLinks;
        let dataAppend = new FormData();
        dataAppend.append("member_id", this.form2.id);
        dataAppend.append("meta_title", this.form2.meta_title ?? "");
        dataAppend.append("id", "");
        dataAppend.append("meta_desc", this.form2.meta_desc);
        dataAppend.append("meta_image", this.form2.meta_image);
        dataAppend.append("url", this.form2.url);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    handleSubmitLinks() {
      let validateForm = this.checkFormLinkValidity();
      if (!validateForm) {
        return;
      } else {
        this.updateLink();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
      }
    },

    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    verificationModal(item) {
      this.form.id = item.id;
      this.showVerificationModal = true;
    },

    clearVerificationModel() {
      this.showVerificationModal = false;
      this.form.reason = "";
      this.form.status = null;
      this.image = "";
    },

    ReasonsModal(id) {
      this.form.id = id;
      this.showReasonModal = true;
      this.fetchreasons();
    },

    clearReasonModel() {
      this.reason = false;
    },

    BankDetailsModal(id) {
      this.form.id = id;
      this.showBankDetailsModal = true;
      this.fetchBankDetails();
    },

    clearBankDetailsModal() {
      this.showBankDetailsModal = false;
      this.form.id = null;
      this.bankDetails = null;
    },

    handleVerificationChange() {
      this.form.reason = "";
    },

    readFile(e, txt) {
      if (txt == "image") {
        this.image = e.target.files[0];
        if (this.image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is more than 2MB",
          });
          this.$refs.image.reset();
          this.image = "";
          return false;
        }
      }
    },

    checkNoteValidity() {
      if (this.form.status == null) {
        this.submitted = true;
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Status is required.",
        });
        return false;
      }
      if (this.form.reason == "") {
        this.submitted = true;
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Reason Filed Required",
        });
        return false;
      }
      return true;
    },

    async SubmitVerificationStatus() {
      try {
        let validateForm = this.checkNoteValidity();
        if (!validateForm) {
          return;
        } else {
          this.$store.commit("loader/updateStatus", true);
          const index = this.tableData.data.findIndex(
            (e) => e.id == this.form.id
          );

          const statusData = new FormData();

          for (var key in this.form) {
            if (key !== "id") {
              statusData.append(key, this.form[key]);
            }
          }
          if (this.image) {
            statusData.append("attachment", this.image);
          }

          const url = `${withdrawRequest.updateStatus}/${this.form.id}`;
          const response = await axios.post(`${apiEndpoint}${url}`, statusData);
          const res = response.data;
          if (res.status) {
            this.tableData.data[index].status = res.response.status;
            this.showVerificationModal = false;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: res.message,
            });
          }
          this.$store.commit("loader/updateStatus", false);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async fetchData() {
      this.$store.commit("loader/updateStatus", true);

      let url = `${withdrawRequest.getwithdrawRequest}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}`;
      try {
        const response = await axios.get(`${apiEndpoint}${url}`);
        const res = response.data;
        if (res.status) {
          this.tableData = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async fetchreasons() {
      this.$store.commit("loader/updateStatus", true);

      const url = `${withdrawRequest.getwithdrawRequest}/${this.form.id}`;
      try {
        const response = await axios.get(`${apiEndpoint}${url}`);
        const res = response.data;
        if (res) {
          this.Reasons = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async fetchBankDetails() {
      this.$store.commit("loader/updateStatus", true);

      const url = `${withdrawRequest.getBankDetails}/${this.form.id}`;
      try {
        const response = await axios.get(`${apiEndpoint}${url}`);
        const res = response.data;
        if (res) {
          this.bankDetails = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
};
